import { useEffect, useState } from "react"

export default function useScreen(smallSize) {
  const [isSmallScreen, setIsSmallScreen] = useState(true)
  useEffect(() => {
    const screen = () => setIsSmallScreen(window.innerWidth <= smallSize)
    if (typeof window !== "undefined") {
      screen()
      window.addEventListener("resize", screen)
      return () => window.removeEventListener("resize", screen)
    }
  })

  return isSmallScreen
}
