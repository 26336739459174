/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from "./logo"
import Nav from "./nav"
import Wrapper from "./wrapper"
import useScreen from "../hooks/useScreen"
import { useState, useEffect } from "react"
import ToggleButton from "./toggle-button"
import useLanguage from "../hooks/useLanguage"

function Header() {
  const isAR = useLanguage() === "ar"
  const isSmallScreen = useScreen(750)
  const [isOpen, setIsOpen] = useState(false)
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const isSSR = typeof window === "undefined"

    const handleScroll = () => {
      setScroll(window.pageYOffset >= 110 ? true : false)
    }

    if (!isSSR) {
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [setScroll])

  return (
    <Styled.div
      as="header"
      sx={{
        backgroundColor: `header`,
        position: scroll ? "sticky" : "static",
        top: scroll ? 0 : -100,
        left: 0,
        right: 0,
        zIndex: 100,
        transition: "all 400ms",
      }}
    >
      <Wrapper>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: 112,
          }}
        >
          <Flex sx={{ alignItems: "center" }}>
            <Link
              to={isAR ? "/" : "/en/"}
              aria-label={isAR ? "الرئيسية" : "Home"}
              sx={{
                color: `muted`,
                textDecoration: `none`,
                width: 203,
                display: "inline-block",
                marginInlineEnd: 20,
              }}
            >
              <Logo />
            </Link>
            <Link
              aria-label={isAR ? "change language" : "تغيير اللغة"}
              role="button"
              to={isAR ? "/en/" : "/"}
              sx={{
                color: `muted`,
                textDecoration: `none`,
                padding: 10,
                ":hover, :active": {
                  color: "primary",
                },
              }}
            >
              {isAR ? "EN" : "AR"}
            </Link>
          </Flex>

          {isSmallScreen && (
            <ToggleButton onClick={() => setIsOpen(!isOpen)} open={isOpen} />
          )}
          {!isSmallScreen && (
            <Nav isOpen={isOpen} isSmallScreen={isSmallScreen} />
          )}
        </Flex>
        {isSmallScreen && <Nav isOpen={isOpen} isSmallScreen={isSmallScreen} />}
      </Wrapper>
    </Styled.div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
