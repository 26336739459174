/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui"
import PropTypes from "prop-types"
import Wrapper from "./wrapper"
import FooterImage from "./footer-image"
import Social from "./social"
import useLanguage from "../hooks/useLanguage"

const Footer = () => {
  const isAR = useLanguage() === "ar"
  const copyRightsText = isAR ? "جميع الحقوق محفوظة" : "All rights reserved"
  return (
    <Styled.div
      as="footer"
      sx={{
        backgroundColor: `footer`,
      }}
    >
      <Wrapper>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Social />
          <Flex
            sx={{
              py: 15,
              alignItems: "center",
            }}
          >
            <small>
              {copyRightsText}, {new Date().getFullYear()}
            </small>
            <div
              to="/"
              sx={{
                color: `muted`,
                textDecoration: `none`,
                width: 203,
                display: "inline-block",
              }}
            >
              <FooterImage />
            </div>
          </Flex>
        </Flex>
      </Wrapper>
    </Styled.div>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
