/** @jsx jsx */
import { Fragment } from "react"

import { Styled, jsx, css } from "theme-ui"
import PropTypes from "prop-types"

import Header from "../components/header"
import Footer from "../components/footer"
import { Global } from "@emotion/core"
import Seo from "../components/seo"
import useLanguage from "../hooks/useLanguage"

const MainLayout = ({ children, title }) => {
  const isAR = useLanguage() === "ar"
  const dir = isAR ? "rtl" : "ltr"

  return (
    <Fragment>
      <Styled.root dir={dir}>
        <Header />
        <Seo title={title} />
        <main
          sx={{ minHeight: "calc(100vh - 187px)", overflow: "hidden" }}
          id="main"
        >
          {children}
        </main>
        <Footer />
        {isAR ? (
          <Global
            styles={css`
              @import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300&display=swap");
            `}
          />
        ) : (
          <Global
            styles={css`
              @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@200&display=swap");
            `}
          />
        )}
        <Global
          styles={css`
            html {
              scroll-behavior: smooth;
            }
            p {
              font-size: 14px;
            }
          `}
        />
      </Styled.root>
    </Fragment>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
