/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import { Link as GLink } from "gatsby"
import useNavigationsQuery from "../hooks/useNavigationsQuery"
import "./animations"
import useLanguage from "../hooks/useLanguage"

export default function Nav({ isSmallScreen, isOpen }) {
  const { site } = useNavigationsQuery()
  const lang = useLanguage()
  const isSSR = () => typeof window === "undefined"

  if (isSSR()) return false
  return (
    <Styled.div as="nav">
      <Styled.ul
        sx={
          isSmallScreen
            ? isOpen
              ? { ...styles.navOpenWrapper, ...styles.animation }
              : { ...styles.navOpenWrapper, ...styles.navClosed }
            : styles.navDefault
        }
      >
        {site.siteMetadata.navigations.map(({ path, ...item }) => (
          <Styled.li
            sx={{
              cursor: "pointer",
              color: "white",
              listStyle: "none",
              backgroundColor: isOpen && "#5a4a2f",
              borderBottom: isOpen && "0.990px solid #4b3b20!important",
              position: "relative",
              "&::before": {
                content: '""',
                width: "0%",
                left: "50%",
                height: "5px",
                backgroundColor: "primary",
                display: "block",
                position: "absolute",
                bottom: 0,
                opacity: 0,
                transition: "0.2s ease-in-out",
              },
              a: {
                textTransform: "uppercase",
                display: isOpen ? "block" : "inline-block",
                padding: isOpen ? 22 : "6px 8px",
                height: isOpen ? "" : 112,
                lineHeight: isOpen ? "" : "112px",
                color: "white",
                fontWeight: lang === "ar" && "600",

                "&.active": {
                  color: "primary",
                },
              },
              ":hover": {
                "&::before": {
                  width: "100%",
                  opacity: 1,
                  left: 0,
                  right: 0,
                },
                a: {
                  textDecoration: "none",
                  color: "primary",
                },
              },
            }}
          >
            <Styled.a
              activeClassName="active"
              partiallyActive={path !== "/"}
              key={path}
              as={GLink}
              to={`${lang === "ar" ? "" : "/en"}${path}`}
              sx={{ color: isOpen && "inherit !important" }}
            >
              {item[`title_${lang}`]}
            </Styled.a>
          </Styled.li>
        ))}
      </Styled.ul>
    </Styled.div>
  )
}

const styles = {
  navDefault: {
    display: "flex",
    p: 0,
    m: 0,
  },
  navOpenWrapper: {
    display: "block",
    position: "relative",
    top: 20,
    left: 0,
    right: 0,
    zIndex: 1,
    mx: 3,
    my: 0,
    p: 0,
    pb: 10,
  },
  animation: {
    animation: "fadeInDown 700ms",
  },
  navClosed: {
    height: 0,
    overflow: "hidden",
  },
}
