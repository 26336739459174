import React from "react"
import { Box } from "theme-ui"

export default function ToggleButton({ open, onClick }) {
  return (
    <Box
      role="button"
      aria-label="toggle button"
      tabIndex={0}
      onKeyPress={onClick}
      onClick={onClick}
      sx={{ m: 2, width: 22 }}
    >
      <Box
        sx={{
          transition: "all ease-in",
          height: "2px",
          mb: 1,
          backgroundColor: "white",
          transformOrigin: open && "10% 10%",
          transform: open && "rotate(45deg)",
        }}
      />
      <Box
        sx={{
          transform: "all ease-in",
          height: "2px",
          mb: 1,
          backgroundColor: "white",
          visibility: open && "hidden",
        }}
      />
      <Box
        sx={{
          transition: "all ease-in",
          height: "2px",
          backgroundColor: "white",
          transformOrigin: open ? " 10% 90%" : "0%",
          transform: open ? "rotate(-45deg)" : "rotate(0)",
        }}
      />
    </Box>
  )
}
