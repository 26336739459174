import React from "react"
import "./animate.css"
import { Animated } from "react-animated-css"
import { useInView } from "react-intersection-observer"
import { useState } from "react"
import { useEffect } from "react"

export default function Animate({
  animationIn,
  isVisible,
  children,
  animationInDelay,
  ...props
}) {
  const isSSR = () => typeof window === "undefined"
  const [isInView, setIsInView] = useState(isSSR())
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    if (!isInView && isVisible && inView) setIsInView(true)
  }, [isVisible, isInView, inView])

  return (
    <Animated
      {...props}
      innerRef={ref}
      animationInDelay={animationInDelay}
      isVisible={isInView}
      animationIn={animationIn}
    >
      {children}
    </Animated>
  )
}

Animate.defaultProps = {
  isVisible: true,
}
