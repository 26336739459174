/** @jsx jsx */
import { jsx, Link } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Icon from "react-icons-kit"
import { linkedin } from "react-icons-kit/fa/linkedin"
import { twitter as twittericon } from "react-icons-kit/fa/twitter"
import { instagram as instagramicon } from "react-icons-kit/fa/instagram"
import { whatsapp as whatsappicon } from "react-icons-kit/fa/whatsapp"

export default function Social() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              linkedIn
              twitter
              google
              instagram
              whatsapp
            }
          }
        }
      }
    `
  )
  const { linkedIn, twitter, instagram, whatsapp } = site.siteMetadata.social

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        a: {
          color: "white",
          width: 28,
          textAlign: "center",
          display: "block",
          borderRadius: 3,
          backgroundColor: "black",
          marginInlineEnd: 3,
          transition: "0.2s ease-in-out",
          "&:hover": {
            backgroundColor: "primary",
          },
        },
      }}
    >
      <Link
        target="_blank"
        rel="noopener"
        aria-label="رابط صفحة نمر على لينكد ان"
        href={whatsapp}
      >
        <Icon icon={whatsappicon} />
      </Link>
      <Link
        target="_blank"
        rel="noopener"
        aria-label="رابط صفحة نمر على لينكد ان"
        href={linkedIn}
      >
        <Icon icon={linkedin} />
      </Link>
      <Link
        target="_blank"
        rel="noopener"
        aria-label="رابط صفحة نمر على تويتر"
        href={twitter}
      >
        <Icon icon={twittericon} />
      </Link>
      <Link
        target="_blank"
        rel="noopener"
        aria-label="رابط موقع نمر على الانستغرام"
        href={instagram}
      >
        <Icon icon={instagramicon} />
      </Link>
    </div>
  )
}
