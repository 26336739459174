/** @jsx jsx */
import { jsx } from "theme-ui"

export default function Wrapper({ children, style }) {
  return (
    <div
      sx={{
        maxWidth: [1200, 1000, 1300],
        mx: "auto",
        width: "100%",
        px: 15,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
